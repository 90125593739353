import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { decodeArrayToObject } from "../../../app/static_func";

import { setLocalStorageItem, getLocalStorageItem } from "app/static_func";
import Api from "api/requests";

import * as storageKey from "constants/storage-keys";
import * as popups from "constants/popup-types";
import * as routes from "constants/routes";
import * as cardTypes from "constants/card-types";
import Actions from "redux/actions";

import CardA from "./CardA";
import CardB from "./CardB";
import CardC from "./CardC";

import loveIcon from "assets/love-icons/love.svg";
import loveIconUnPressed from "assets/love-icons/love_blank.svg";
import lockedIcon from "assets/icons/locked.svg";
import boughtIcon from "assets/icons/car_keys.svg";
import garage from "assets/icons/garage.svg";
import "./index.scss";
import googleAnalyticsManager from "analytics/googleAnalytics";

function VehicleCard(props) {
  const settings = {
    id: props.id,
    licenseNum: props.licenseNum,
    model: props.model,
    sub_title: props.sub_title,
    year: props.year,
    km: props.km,
    pricePerMonth: props.pricePerMonth,
    price: props.price,
    displayPhoto: props.displayPhoto,
    agency: props.agency,
    isOrdered: props.isOrdered,
    isBought: props.isBought,
    isAvailable: props.isAvailable,
  };

  const { isFromIframe = false } = props;

  const [isLoved, setIsLoved] = useState(false);
  const dispatch = useDispatch();
  const deviceState = useSelector((store) => store.deviceState);
  const [isImageBroken, setIsImageBroken] = useState(false);
  const history = useHistory();
  const favorites = useSelector((store) => store.Favorites);
  const isLocked = settings.isOrdered || settings.isBought ? "lockedCard" : "";
  const enableFunding = props.enableFunding;

  useEffect(() => {
    if (favorites) {
      if (favorites.records) {
        const favoritesIds = favorites.records.map(item => item.id);
        setIsLoved(favoritesIds.includes(settings.id) ? true : false);
      } else {
        if (isLoved) setIsLoved(false);
      }
    }
  }, [favorites]);

  const routeTo = getRoute();

  function getAllFavorites() {
    if (getLocalStorageItem(storageKey.FAVORITES)) {
      return JSON.parse(getLocalStorageItem(storageKey.FAVORITES));
    }
  }

  function getRoute() {
    const params = new URLSearchParams(document.location.search);
  
    if (!(settings.isOrdered || settings.isBought)) {
      //in case routing from the favorites popup

      let converted_model_name = settings.model.replace(' ', '_');
      const title = converted_model_name + "_שנת_" + settings.year;
      const searchParams = isFromIframe && params.toString() ? `?${params.toString()}` : '';

      return (
        routes.car + "/" + settings.id + "/" + title + searchParams
      );
    }
    return routes.root;
  }

  function handleCardOnClick() {
    googleAnalyticsManager.productClick(settings)
    dispatch(Actions.removePopup(popups.FAVORITES));
  }

  function onImageError() {
    setIsImageBroken(true);
  }

  function getImages(NotFound) {
    return (
      <div className="images">
        <img
          className={`default-image ${isImageBroken ? "" : "hide"}`}
          src={NotFound}
          alt="\"
        />
        <img
          src={props.displayPhoto ? props.displayPhoto : NotFound}
          alt={settings.model + ' ' + settings.year}
          onError={onImageError}
          className={`display ${isImageBroken ? "hide" : ""} ${props.displayPhoto ? 'withImage' : 'noImage'}`}
        />
        <img
          src={isLoved ? loveIcon : loveIconUnPressed}
          alt="\"
          className="loved"
          onClick={(event) => handleLoveClick(event)}
        />
      </div>
    );
  }

  function handleLoveClick(e) {
    e.stopPropagation();
    e.preventDefault();

    const id = settings.id;
    let allFavorites = getAllFavorites();
    let isLoved = false;
    let newFavorites = [];

    if (allFavorites) {
      if (allFavorites.includes(id)) {
        newFavorites = allFavorites.filter(function (value) {
          return value !== id;
        });
        allFavorites = newFavorites;
      } else {
        const productName = settings.model + ' ' + settings.sub_title;
        googleAnalyticsManager.AddProductToWishlist({ productName })
        allFavorites.push(id);
        isLoved = true;
      }
    } else {
      allFavorites = [];
      allFavorites.push(id);
      isLoved = true;
    }

    //update the local storage
    setLocalStorageItem(storageKey.FAVORITES, JSON.stringify(allFavorites));
    setIsLoved(isLoved);
    let payload = decodeArrayToObject(allFavorites, 'id');
    Object.keys(payload).length ?
      Api.getSpecificCarById({ payload: payload })
      : store.dispatch(Actions.setFavorites(false));
  }


  useEffect(() => {
    const allFavorites = getAllFavorites();
    if (allFavorites !== undefined) {
      if (allFavorites.includes(props.id)) {
        setIsLoved(true);
      }
    }
  }, []);

  switch (props.cardType) {
    case cardTypes.CardA:
      return (
        <CardA
          {...settings}
          btnClick={handleCardOnClick}
          routeTo={routeTo}
          lockedIcon={lockedIcon}
          boughtIcon={boughtIcon}
          garage={garage}
          getImages={getImages}
          isLocked={isLocked}
          enableFunding={enableFunding}
          isFromIframe={isFromIframe}
        />
      );
    case cardTypes.CardB:
      return (
        <CardB
          {...settings}
          btnClick={handleCardOnClick}
          routeTo={routeTo}
          lockedIcon={lockedIcon}
          boughtIcon={boughtIcon}
          garage={garage}
          getImages={getImages}
          isLocked={isLocked}
          enableFunding={enableFunding}
          isFromIframe={isFromIframe}
        />
      );
    case cardTypes.CardC:
      return (
        <CardC
          {...settings}
          btnClick={handleCardOnClick}
          routeTo={routeTo}
          lockedIcon={lockedIcon}
          boughtIcon={boughtIcon}
          garage={garage}
          getImages={getImages}
          isLocked={isLocked}
          enableFunding={enableFunding}
          isFromIframe={isFromIframe}
        />
      );
    default:
      return (
        <CardA
          {...settings}
          btnClick={handleCardOnClick}
          lockedIcon={lockedIcon}
          boughtIcon={boughtIcon}
          garage={garage}
          getImages={getImages}
          isLocked={isLocked}
          enableFunding={enableFunding}
          isFromIframe={isFromIframe}w
        />
      );
  }
}

export default VehicleCard;
