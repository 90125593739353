import React from "react";
import "./burger.scss";
import burger from "assets/icons/burger.svg";

const Burger = ({ onClick }) => (
  <div className="burger-wrapper">
    <button className="burger_btn" onClick={onClick}>
      <img src={burger} alt="תפריט" />
    </button>
  </div>
);
export default Burger;
