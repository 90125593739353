import React, { Component } from 'react';

import './main_menu.scss';
import { connect } from "react-redux";
import DynamicLink from "components/routing/dynamicLink/dynamicLink";

import { getFullMediaUrl, decodeArrayToObject, sortByOrderNum } from "app/static_func";


class MainMenu extends Component {

    render() {

        const menuItems = JSON.parse(JSON.stringify(this.props.menu));
        const menuList = Object.values(menuItems)
        const orderedMenuList = sortByOrderNum(menuList)

        const menu = orderedMenuList.map((item) => {
            return (
                <li className="item" key={item.id}>
                        <DynamicLink
                            data={item.link}
                        >
                            <span> {item.title} </span>
                        </DynamicLink>
                    </li>
            )
        })

        return (
            <nav className="main_menu">
                <ul>
                    {menu}
                </ul>
            </nav>
        )
    }
}


//connect to redux store
const mapStateToProps = store => {
    return {
        menu: store.gd['main_menu']
    }
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(MainMenu);
