export const GENERAL_MESSAGE = "GENERAL_MESSAGE";
export const API_MESSAGE = "API_MESSAGE";
export const API_ERROR = "API_ERROR";
export const FAVORITES = "FAVORITES";
export const CAR_ALBUM = "CAR_ALBUM";
export const VALIDATION_CODE = "VALIDATION_CODE";
export const ADVERT = "ADVERT";
export const TEST_FORM = "TEST_FORM";
export const VIDEO = "VIDEO";
export const OTP = "OTP";
export const SmartAgent = "SMART_AGENT";
export const Generic = "GENERIC";
export const GALLERY = "GALLERY";
export const ENSURE_ERROR = "ENSURE_ERROR";
