import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {matchPath, useLocation} from 'react-router-dom';
import {MetaTags} from 'react-meta-tags';
import ReactHtmlParser from "react-html-parser";

import Api from "api/requests";
import * as routes from 'constants/routes'

const Meta = (props) => {

	const metaTagsArr = useSelector(store => store.metaTags)
	const contentPages = useSelector(store => store.RouteTable)
	const {pathname} = useLocation();
	const [currMetaTags, setCurrMetaTags] = useState(null);

	useEffect(() => {
		requestTags();
	}, [pathname])

	const requestTags = () => {
		const route = getRoute();
		let payload = {route};

		const objectId = getObjectId(route);
		if (objectId) payload.objectId = objectId;

		const keyToCheck = objectId ? `${route}-${objectId}` : route;

		if (keyToCheck in metaTagsArr) {
			setCurrMetaTags(metaTagsArr[keyToCheck])
			return
		}

		Api.getMetaTags({payload}).then((res) => setCurrMetaTags(res?.data?.tags))

	}

	const getRoute = () => {
		const currPath = `/${pathname.split('/')[1]}`;
		let routeName = '';

		for (let route in routes) {
			if (routes[route] === currPath) {
				routeName = route;
			}
		}

		if (!routeName) {
			routeName = 'content';
		}

		return routeName;
	}

	const getObjectId = (routeName) => {
		if (routeName === 'car' || routeName === 'order' || routeName === 'modelCarResults') return; //order and car pages are static pages

		const objectId = routeName === 'content' ? getContentId() : getIdFromParams();

		return objectId;
	}

	const getIdFromParams = () => {

		const match = matchPath(pathname, {
			path: `/${pathname.split('/')[1]}` + "/:id",
			exact: false,
			strict: false,
		});

		const objectId = match?.params?.id;
		return objectId;
	}

	const getContentId = () => {
		let objectId = "";
		for (let pageKey in contentPages) {
			let contentPage = contentPages[pageKey];
			if (`/${contentPage.title}` === pathname) {
				objectId = contentPage.id;
			}
		}
		return objectId;
	}

	const getMetaTags = () => {

		const metaTags = [];
		if (currMetaTags) {
			for (let key in currMetaTags.meta_tags) {
				metaTags.push(
					<meta name={key} content={currMetaTags.meta_tags[key]} key={key}/>
				);

				if (key === "description" || key === "title") {
					metaTags.push(
						<meta
							property={"og:" + key}
							content={currMetaTags.meta_tags[key]}
							key={key + "og"}
						/>
					);
				}
			}
		}
		return metaTags;
	}

	const getExtraTags = () => {
		let tags = [];
		currMetaTags?.extra_tags.forEach((item) => {
			tags.push(ReactHtmlParser(item));
		});
		return tags;
	}

	return (
		<>
			<MetaTags>
				{currMetaTags &&
					(<>
						{getMetaTags()}
						<title>{currMetaTags.meta_tags["title"]}</title>
						{getExtraTags()}
					</>)}
			</MetaTags>
		</>
	)
}

export default Meta;
