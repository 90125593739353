import React, { Component } from "react";
import * as routes from "constants/routes";
import "./ErrorBoundary.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  onBtnClickHandler = function () {
    window.location.href = routes.home;
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-wrapper">
          <h3 className="error-boundary-title">שגיאה כללית</h3>
          <button className="btn-go-to-home" onClick={this.onBtnClickHandler}>
            חזור לעמוד הבית
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
