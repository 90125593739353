import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import SwiperCore from "swiper";


import { useSelector } from "react-redux";
import { useRef } from "react";

import * as routes from "constants/routes";
import { getFullMediaUrl, decodeArrayToObject, sortByOrderNum } from "app/static_func";

import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";

import "./index.scss";
import Arrow from "assets/icons/arrow.svg";

import Car from "assets/mock/car.png";
import googleAnalyticsManager from "analytics/googleAnalytics";

SwiperCore.use([Navigation, Pagination]);

function Stairs(props) {
  const deviceState = useSelector((store) => store.deviceState);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const is_laptop = deviceState.device === "desktop";
  const { options, history, stairs_id, showText = false } = props;
  let slideToShow, dots, arrows;
  if (deviceState.isMobile) {
    slideToShow = 1.7;
    dots = true;
    arrows = false;
  } else if (deviceState.isTablet) {
    slideToShow = 4.1;
    dots = true;
    arrows = false;
  } else {
    if (is_laptop) slideToShow = "auto";
    else slideToShow = "auto";
    dots = false;
    arrows = {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    };
  }

  function handleImageClick(pram, id, title) {
    const categoryId = decodeArrayToObject([id.toString()], 'category_id')
    const urlSearchParams = pram === 'category_id' ?
      `?${Object.keys(categoryId)[0]}=${id.toString()}` :
      `?${pram}=${id}`;

    const searchOption = pram === 'category_id' ? 'category' : 'budget'
    googleAnalyticsManager.SearchByCategoey({
      event: title,
      searchOption
    })

    history.push(routes.results + urlSearchParams);
  }
  const swiperSettings = {
    spaceBetween: 1,
    pagination: dots,
    navigation: arrows,
    slidesPerView: slideToShow,
  };


  function getSlides() {
    const slidesArr = [...options]
    const slidesToShow = stairs_id === 'price_stairs_id' ? options : sortByOrderNum(slidesArr);
    const slides = slidesToShow.map((item, index) => {
      return (
        (item.active || stairs_id === 'price_stairs_id') &&
        <SwiperSlide key={index}>
          <div className="slide-wrapper">
            <div className="slide image-wrapper" key={index}>
              <img
                className="image"
                src={
                  item.image.path !== "" ? getFullMediaUrl(item.image.path) : Car
                }
                alt={item.image.alt}
                onClick={() => {
                  handleImageClick(stairs_id, item.id, item.title);
                }}
              />


            </div>

            {showText && <div className="text">{item.title}</div>}


          </div>
        </SwiperSlide>
      );
    });
    return slides;
  }
  return (
    <div className="stairs-wrapper">
      <Swiper className="slider" {...swiperSettings}>
        {arrows && (
          <>
            <div className={"prev-arrow-wrapper "}>
              <img className="prev-arrow" src={Arrow} ref={navigationPrevRef} alt="\" />
            </div>
            <div className={"next-arrow-wrapper "}>
              <img className="next-arrow" src={Arrow} ref={navigationNextRef} alt="\" />
            </div>
          </>
        )}

        {getSlides()}
      </Swiper>
    </div>
  );
}
export default Stairs;
