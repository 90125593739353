import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let dataReducers = {};
let dataActions = {};

/*---------------------------------------------------------------*/
export const gdSlice = createSlice({
  name: "gd",
  initialState: false,
  reducers: {
    setGd: (state, action) => action.payload,
  },
});
Slices.push(gdSlice);
/*---------------------------------------------------------------*/

export const areasSlice = createSlice({
  name: "areas",
  initialState: [],
  reducers: {
    setAreas: (state, action) => action.payload,
  },
});
Slices.push(areasSlice);
/*---------------------------------------------------------------*/

export const userSlice = createSlice({
  name: "userData",
  initialState: false,
  reducers: {
    setUser: (state, action) => action.payload,
  },
});
Slices.push(userSlice);
/*---------------------------------------------------------------*/

export const metaTagsSlice = createSlice({
  name: "metaTags",
  initialState: {},
  reducers: {
    setMetaTags: (state, action) => {
      state[action.payload.route] = action.payload.val;
    },
  },
});
Slices.push(metaTagsSlice);
/*---------------------------------------------------------------*/

export const appointmentsSlice = createSlice({
  name: "Appointments",
  initialState: false,
  reducers: {
    setAppointments: (state, action) => action.payload,
  },
});
Slices.push(appointmentsSlice);
/*---------------------------------------------------------------*/

export const autoCompleteResultsSlice = createSlice({
  name: "AutoCompleteResults",
  initialState: false,
  reducers: {
    setAutoCompleteResults: (state, action) => action.payload,
  },
});
Slices.push(autoCompleteResultsSlice);
/*---------------------------------------------------------------*/

export const cgSlice = createSlice({
  name: "CG",
  initialState: false,
  reducers: {
    setCG: (state, action) => action.payload,
  },
});
Slices.push(cgSlice);
/*---------------------------------------------------------------*/

export const contentPageSlice = createSlice({
  name: "ContentPage",
  initialState: false,
  reducers: {
    setContentPage: (state, action) => action.payload,
  },
});
Slices.push(contentPageSlice);
/*---------------------------------------------------------------*/

export const favoritesSlice = createSlice({
  name: "Favorites",
  initialState: false,
  reducers: {
    setFavorites: (state, action) => action.payload,
  },
});
Slices.push(favoritesSlice);
/*---------------------------------------------------------------*/

export const validSmsSlice = createSlice({
  name: "ValidSMS",
  initialState: false,
  reducers: {
    setValidationSMS: (state, action) => action.payload,
  },
});
Slices.push(validSmsSlice);
/*---------------------------------------------------------------*/

export const leadsSlice = createSlice({
  name: "LeadStatus",
  initialState: 0,
  reducers: {
    setLeadStatus: (state, action) => action.payload,
  },
});
Slices.push(leadsSlice);
/*---------------------------------------------------------------*/

export const manufacturersResultsSlice = createSlice({
  name: "ManufactureresResults",
  initialState: [],
  reducers: {
    setManufacturersResults: (state, action) => action.payload,
  },
});
Slices.push(manufacturersResultsSlice);
/*---------------------------------------------------------------*/

export const carResultsSlice = createSlice({
  name: "carsResults",
  initialState: [],
  reducers: {
    appendCarsResults: (state, action) => {
      return [...state, ...action.payload];
    },
    setCarsResults: (state, action) => action.payload,
  },
});
Slices.push(carResultsSlice);
/*---------------------------------------------------------------*/

export const carSlice = createSlice({
  name: "Car",
  initialState: false,
  reducers: {
    setCarResults: (state, action) => action.payload,
    resetCarDetails: (state, action) => false,
  },
});
Slices.push(carSlice);
/*---------------------------------------------------------------*/

export const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState: false,
  reducers: {
    setSearchResults: (state, action) => action.payload,
  },
});
Slices.push(searchResultsSlice);
/*---------------------------------------------------------------*/

export const smsSlice = createSlice({
  name: "SMS",
  initialState: false,
  reducers: {
    setSMS: (state, action) => action.payload,
  },
});
Slices.push(smsSlice);
/*---------------------------------------------------------------*/

export const summarySlice = createSlice({
  name: "Summary",
  initialState: false,
  reducers: {
    setSummary: (state, action) => action.payload,
  },
});
Slices.push(summarySlice);
/*---------------------------------------------------------------*/
export const refreshSlice = createSlice({
  name: "Refresh",
  initialState: false,
  reducers: {
    setRefresh: (state, action) => action.payload,
  },
});
Slices.push(refreshSlice);
/*---------------------------------------------------------------*/
export const resultsFiltersSlice = createSlice({
  name: "ResultsFilters",
  initialState: false,
  reducers: {
    setResultsFilters: (state, action) => action.payload,
  },
});
Slices.push(resultsFiltersSlice);

/*---------------------------------------------------------------*/

export const minMaxValuesSlice = createSlice({
  name: "minMaxValues",
  initialState: {},
  reducers: {
    setMinMaxValues: (state, action) => action.payload,
  },
});
Slices.push(minMaxValuesSlice);
/*---------------------------------------------------------------*/

export const selectedCheckboxesSlice = createSlice({
  name: "selectedCheckboxes",
  initialState: [],
  reducers: {
    setSelectedCheckboxes: (state, action) => action.payload,
  },
});
Slices.push(selectedCheckboxesSlice);
/*---------------------------------------------------------------*/
export const orderDetailsSlice = createSlice({
  name: "OrderDetails",
  initialState: false,
  reducers: {
    setOrderDetails: (state, action) => action.payload,
  },
});
Slices.push(orderDetailsSlice);
/*---------------------------------------------------------------*/
export const allAgenciesSlice = createSlice({
  name: "allAgencies",
  initialState: [],
  reducers: {
    appendAllAgencies: (state, action) => {
      return [...state , action.payload]
    },
    setAllAgencies: (state, action) => action.payload,
  },
});
Slices.push(allAgenciesSlice);
/*---------------------------------------------------------------*/
export const allModelsSlice = createSlice({
  name: "allModels",
  initialState: [],
  reducers: {
    setAllModels: (state, action) => action.payload,
  },
});
Slices.push(allModelsSlice);
/*---------------------------------------------------------------*/
export const agencyDetailsSlice = createSlice({
  name: "agencyDetails",
  initialState: [],
  reducers: {
    setAgencyDetails: (state, action) => action.payload,
  },
});
Slices.push(agencyDetailsSlice);
/*---------------------------------------------------------------*/
export const modelsSlice = createSlice({
  name: "models",
  initialState: [],
  reducers: {
    setModels: (state, action) => action.payload,
  },
});
Slices.push(modelsSlice);
/*---------------------------------------------------------------*/
export const smartAgencyCarResults = createSlice({
  name: "smartAgencyCarResults",
  initialState: [],
  reducers: {
    setSmartAgencyCarResults: (state, action) => action.payload,
    resetSmartAgencyCarResults: (state, action) => []
  },
});
Slices.push(smartAgencyCarResults);
/*---------------------------------------------------------------*/

export const ensureCarDetails = createSlice({
  name: "ensureCarDetails",
  initialState: false,
  reducers: {
    setEnsureCarDetails: (state, action) => action.payload,
  },
});
Slices.push(ensureCarDetails);

/*---------------------------------------------------------------*/

export const magazineSlice = createSlice({
  name: "magazines",
  initialState: false,
  reducers: {
    setMagazines: (state, action) => action.payload,
  },
});
Slices.push(magazineSlice);
/*---------------------------------------------------------------*/

export const finishOrderSlice = createSlice({
  name: "finishOrder",
  initialState: false,
  reducers: {
    setFinishOrder: (state, action) => action.payload,
  },
});
Slices.push(finishOrderSlice);
/*---------------------------------------------------------------*/

export const baseFiltersSlice = createSlice({
  name: "baseFilters",
  initialState: [],
  reducers: {
    setBaseFilters: (state, action) => action.payload,
  },
});
Slices.push(baseFiltersSlice);
/*---------------------------------------------------------------*/

export const modelDetailsSlice = createSlice({
  name: "modelDetails",
  initialState: [],
  reducers: {
    setModelDetails: (state, action) => action.payload,
  },
});
Slices.push(modelDetailsSlice);

/*---------------------------------------------------------------*/

export const utmParamsSlice = createSlice({
  name: "utmParams",
  initialState: {},
  reducers: {
    setUtmParams: (state, action) => action.payload,
  },
});
Slices.push(utmParamsSlice);

/*---------------------------------------------------------------*/

export const iframeModelCarsSlice = createSlice({
  name: "iframeModelCars",
  initialState: [],
  reducers: {
    appendIframeModelCars: (state, action) => {
      return [...state, ...action.payload];
    },
    setIframeModelCars: (state, action) => action.payload,
  },
});
Slices.push(iframeModelCarsSlice);

/*---------------------------------------------------------------*/

export const iframeModelCarsInfoSlice = createSlice({
  name: "iframeModelCarsInfo",
  initialState: false,
  reducers: {
    setIframeModelCarsInfo: (state, action) => action.payload,
  },
});
Slices.push(iframeModelCarsInfoSlice);

/*---------------------------------------------------------------*/


//build export objects
for (const Slice of Slices) {
  dataActions = { ...dataActions, ...Slice.actions };
  let reducer = { [Slice.name]: Slice.reducer };
  dataReducers = { ...dataReducers, ...reducer };
}

export { dataActions };
export { dataReducers };

export const { setGd } = gdSlice.actions;
