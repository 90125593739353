import "./index.scss"


function HomeLoader(props) {

    const { className } = props

    return (
        <>
            <div className={`loadingio-spinner-rolling-ckirmawi2k7 ${className || ''}`}><div className="ldio-hjd4yi48j29">
                <div></div>
            </div></div>
        </>

    )
}

export default HomeLoader;