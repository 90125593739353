import { stateReducers } from "redux/slices/state";
import { dataReducers } from "redux/slices/data";
import { formReducers } from "redux/slices/forms";
import { configReducers } from "redux/slices/config";

let Reducers = {};
for (let reducer in stateReducers) {
  Reducers[reducer] = stateReducers[reducer];
}
for (let reducer in dataReducers) {
  Reducers[reducer] = dataReducers[reducer];
}
for (let reducer in formReducers) {
  Reducers[reducer] = formReducers[reducer];
}
for (let reducer in configReducers) {
  Reducers[reducer] = configReducers[reducer];
}
export default Reducers;
