import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import SwiperCore from "swiper";

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions";

import VehicleCard from "components/forms/vehicle_card";
import googleAnalyticsManager from "analytics/googleAnalytics";

import * as cardTypes from "constants/card-types";
import * as keys from "constants/storage-keys";
import * as popupTypes from "constants/popup-types";

import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";

import "./index.scss";
import car from "assets/mock/carCardVehicle.png";
import Arrow from "assets/icons/arrow.svg";
import {
  getFullMediaUrl,
  isCombinedLoanFunding,
} from "../../../../app/static_func";
import { NavLink } from "react-router-dom";
import * as routes from "../../../../constants/routes";
import CountUp from "react-countup";
import LottieAnimation from "../../../../components/carAnimation/car-animation.js";
import carMobileAnimation from "../../../../components/carAnimation/carDownAnimationMobile.json";
import carDesktopAnimation from "../../../../components/carAnimation/carDownAnimationDekstop.json";

SwiperCore.use([Navigation, Pagination]);

function NewCars(props) {
  const deviceState = useSelector((store) => store.deviceState);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const text = useSelector((store) => store.gd.translationsArr);
  const cars = useSelector((store) => store.gd.last_cars_updated);
  const { history } = props;
  const { showAllCarsButton = true } = props;
  const is_laptop = deviceState.device === "desktop";
  const clickHereToWatch = useSelector(
    (store) => store.gd.translationsArr["click-here-to-watch"]
  );
  const carInSite = useSelector(
    (store) => store.gd.translationsArr["car-in-site"]
  );
  const numOfActiveCars = useSelector((store) => store.gd["active_cars"]);

  let slideToShow, dots, arrows;
  if (deviceState.isMobile) {
    slideToShow = "auto";
    dots = true;
    arrows = false;
  } else if (deviceState.isTablet) {
    slideToShow = "auto";
    dots = true;
    arrows = false;
  } else {
    if (is_laptop) slideToShow = "auto";
    else slideToShow = "auto";
    dots = false;
    arrows = {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    };
  }
  const swiperSettings = {
    spaceBetween: 1,
    pagination: dots,
    navigation: arrows,
    slidesPerView: slideToShow,
  };

  useEffect(() => {
    callGoogleAnalyticsEvent();
  }, []);

  const callGoogleAnalyticsEvent = () => {
    googleAnalyticsManager.productImpressions(props.cars);
  };

  function getSlides() {
    const cardType = deviceState.isDesktop ? cardTypes.CardB : cardTypes.CardA;
    const slides = props.cars.map((car, index) => {
      const pricePerMonth = isCombinedLoanFunding(car.year)
        ? car.monthly_price
        : car.payment_loan;

      return (
        <SwiperSlide key={index}>
          <div className="vehicleCard-wrapper">
            <VehicleCard
              key={car.id}
              id={car.id}
              licenseNum={car["license_num"]}
              model={car.title}
              sub_title={car.sub_title}
              year={car.year}
              km={car.km}
              pricePerMonth={pricePerMonth}
              price={car.price}
              displayPhoto={car.image}
              agency={car.agency_name}
              isOrdered={car.flags.ordered}
              isBought={car.flags.sold}
              isAvailable={car.flags.order_available}
              cardType={cardType}
              history={history}
              enableFunding={car.enable_funding}
            />
          </div>
        </SwiperSlide>
      );
    });
    return slides;
  }

  function getAllactiveCars() {
    return (
      <NavLink className="wrapper-buttons-titles" to={routes.results}>
        {deviceState.notDesktop && (
          <LottieAnimation animation={carMobileAnimation} />
        )}
        {!deviceState.notDesktop && (
          <LottieAnimation
            animation={carDesktopAnimation}
            className="animation-car"
          />
        )}
        <div className="all-titles-and-number">
          <div className="numbers-and-title">
            <h1 className="number-cars-insite">{numOfActiveCars}</h1>
            <h4 className="title-car-insite">{carInSite}</h4>
          </div>
          <div className="click-here-div">
            <h3 className="click-here-to-watch">{clickHereToWatch}</h3>
          </div>
        </div>
      </NavLink>
    );
  }
  return (
    <div className="slider-wrapper-new-cars">
      <div className="wrapper-title-and-navigator">
        <h2 className="title">{props.title}</h2>
        {showAllCarsButton && getAllactiveCars()}
      </div>
      <Swiper className="slider" {...swiperSettings}>
        {arrows && (
          <>
            <div className={"prev-arrow-wrapper "}>
              <img
                className="prev-arrow"
                src={Arrow}
                ref={navigationPrevRef}
                alt="\"
              />
            </div>
            <div className={"next-arrow-wrapper "}>
              <img
                className="next-arrow"
                src={Arrow}
                ref={navigationNextRef}
                alt="\"
              />
            </div>
          </>
        )}
        {getSlides()}
      </Swiper>
    </div>
  );
}

export default NewCars;
