import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as routes from 'constants/routes';


class DynamicLink extends Component {

    render() {

        const { data = {}, className='' } = this.props;
        let params    = '';
        let component = <span className= { className }> missing link data { this.props.children } </span>;

        if (data.type) {
            if (data.type === 'internal') {
                if (Object.keys(data.parameters).length > 0) {
                    params = '/' + data.parameters.id;
                }
                component = <NavLink to={routes[data.route] + params} className={className}>
                    {this.props.children}
                </NavLink>;
            }

            else if (data.type === 'contentPage') {
                component = <NavLink to={data.route} className={className}>
                    {this.props.children}
                </NavLink>;
            }
            else {
                component = <a href={data.route} rel="noopener noreferrer" target="_blank" className={className}>
                    {this.props.children}
                </a>
            }
        }

        return (
            <Fragment>
                { component }
            </Fragment>
        )
    }
}
//connect to redux store
const mapStateToProps = store => {
    return {
        gd: store.gd,
    }
}

export default connect(mapStateToProps, null, null, { pure: false })(DynamicLink);
