import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let configReducers = {};
let configActions = {};

/*---------------------------------------------------------------*/
export const routeTableSlice = createSlice({
  name: "RouteTable",
  initialState: false,
  reducers: {
    setRouteTable: (state, action) => {
      let table = [];
      for (let key in action.payload){
        table.push({id:action.payload[key], title:key});
      }
      return table;
    }
  },
});
Slices.push(routeTableSlice);
/*---------------------------------------------------------------*/
export const urlSlice = createSlice({
  name: "url",
  initialState: { media_url: window.REACT_APP_MEDIA_HOST },
  reducers: {
    setUrl: (state, action) => action.payload,
  },
});
Slices.push(urlSlice);
/*---------------------------------------------------------------*/

//build export objects
for (const Slice of Slices) {
  configActions = { ...configActions, ...Slice.actions };
  let reducer = { [Slice.name]: Slice.reducer };
  configReducers = { ...configReducers, ...reducer };
}

export { configActions };
export { configReducers };
