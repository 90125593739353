import React, { useRef, useEffect } from "react";
import { lazy, Suspense } from 'react'
import { useState } from "react"
import { useSelector } from "react-redux";


import HomeLoader from "components/animation/HomeLoader";

// components
import NewCars from "./sections/NewCars";
import Slider from "containers/home/sections/slider/Slider";
import Search from "./sections/search/index.js";

// import InfoGraphic from "./sections/info_graphic/index";
// import Statistics from "./sections/statistics/index";
// import Recommendations from "./sections/recommendations/index";
// import Steps from "./sections/steps/steps";
// import TradeIn from "./sections/tradein/tradein";

const InfoGraphic = lazy(() => import("containers/home/sections/info_graphic/"));
const Statistics = lazy(() => import("containers/home/sections/statistics/"));
const Recommendations = lazy(() => import("containers/home/sections/recommendations/"));
const Steps = lazy(() => import("containers/home/sections/steps/steps"));
const TradeIn = lazy(() => import("containers/home/sections/tradein/tradein"));

// const NewCars = lazy(() => import("./sections/NewCars"));
// const Slider = lazy(() => import("containers/home/sections/slider/Slider"));
// const Search = lazy(() => import("./sections/search/index.js"));


//assets
import "./home.scss";
import SaleCarMobile from "assets/Images/sale-car.jpg";
// import SaleCarMobile from "assets/Images/sale-car-mobile.jpg";
import SaleCarDesktop from "assets/Images/sale-car.jpg";
// import SaleCarDesktop from "assets/Images/sale-car-desktop.jpg";


function Home() {
  const cars = useSelector((store) => store.gd.last_cars_updated);
  const text = useSelector((store) => store.gd.translationsArr);
  const timeInterval = useSelector((store) => store.gd.main_slider_images_time_interval);
  
  const sliderSettings = {
    timePerSlide: timeInterval * 1000 || 3000,
    slidesPerView: 1,
    loop: true,
  };

  return (
    <div className="home">
      
      <Slider {...sliderSettings} />
      <Search
        imageMobile={SaleCarMobile}
        imageDesktop={SaleCarDesktop}
      />
      <NewCars 
      cars={cars}
      title={text["new_cars_on_website"]}
      />

      <IsInView className="info-graphic-section">
        <Suspense fallback={<div className="home-lottie-container"><HomeLoader /></div>}>
          <InfoGraphic />
        </Suspense>
      </IsInView>

      <IsInView className="steps-section">
        <Suspense fallback={<div className="home-lottie-container"><HomeLoader /></div>}>
          <Steps />
        </Suspense>
      </IsInView>

      <IsInView className="trade-in-section">
        <Suspense fallback={<div className="home-lottie-container"><HomeLoader /></div>}>
          <TradeIn />
        </Suspense>
      </IsInView>

      <IsInView className="statistics-section">
        <Suspense fallback={<div className="home-lottie-container"><HomeLoader /></div>}>
          <Statistics />
        </Suspense>
      </IsInView>

      <IsInView className="recommendations-section">
        <Suspense fallback={<div className="home-lottie-container"><HomeLoader /></div>}>
          <Recommendations />
        </Suspense>
      </IsInView>
    </div >
  );

}


export default Home;



function IsInView(props) {

  const ref = useRef(null)
  const [isInView, setIsInView] = useState(false);
  const { className } = props;

  const callbackFunction = (entries) => {

    const [entry] = entries;

    setIsInView(entry.isIntersecting);

  }

  const options = {
    root: document.body,
    rootMargin: "50px",
    threshold: 0.0,
  }

  useEffect(() => {

    const observer = new IntersectionObserver(callbackFunction, options);

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    }

  }, [ref])


  return (
    <div ref={ref} className={className}>
      {isInView && props.children}
    </div>
  )

}

