import { stateActions } from "redux/slices/state";
import { formActions } from "redux/slices/forms";
import { dataActions } from "redux/slices/data";
import { configActions } from "redux/slices/config";

const Actions = {
  ...stateActions,
  ...formActions,
  ...dataActions,
  ...configActions,
};
export default Actions;
