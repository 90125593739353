import { createSlice } from "@reduxjs/toolkit";
import { generateUniqueId } from "app/static_func";

let Slices = [];
let stateReducers = {};
let stateActions = {};

export const deviceSlice = createSlice({
  name: "deviceState",
  initialState: false,
  reducers: {
    setDeviceState: (state, action) => action.payload,
  },
});
Slices.push(deviceSlice);
/*---------------------------------------------------------------*/

export const loaderSlice = createSlice({
  name: "loaderState",
  initialState: false,
  reducers: {
    setLoader: (state, action) => action.payload,
  },
});
Slices.push(loaderSlice);
/*---------------------------------------------------------------*/

export const popupsSlice = createSlice({
  name: "popupsArray",
  initialState: [],
  reducers: {
    addPopup: (state, action) => {
      action.payload.key = generateUniqueId(16);
      state = state.push(action.payload);
    },
    removePopup: (state) => {
      state = state.pop();
    },
  },
});
Slices.push(popupsSlice);
/*---------------------------------------------------------------*/

export const requestingSlice = createSlice({
  name: "requestingState",
  initialState: false,
  reducers: {
    requestStarted: (state, action) => true,
    requestEnded: (state, action) => false,
  },
});
Slices.push(requestingSlice);
/*---------------------------------------------------------------*/

export const burgerSlice = createSlice({
  name: "burgerMenuState",
  initialState: false,
  reducers: {
    setBurger: (state, action) => action.payload,
  },
});
Slices.push(burgerSlice);
/*---------------------------------------------------------------*/

export const burgerAnimateSlice = createSlice({
  name: "burgerMenuAnimate",
  initialState: false,
  reducers: {
    setBurgerAnimate: (state, action) => action.payload,
  },
});
Slices.push(burgerAnimateSlice);
/*---------------------------------------------------------------*/

export const currentLocationSlice = createSlice({
  name: "currentLocationState",
  initialState: {},
  reducers: {
    setCurrentLocation: (state, action) => action.payload,
  },
});
Slices.push(currentLocationSlice);

/*---------------------------------------------------------------*/

export const isIframe = createSlice({
  name: "isIframe",
  initialState: false,
  reducers: {
    setIframe: (state, action) => action.payload,
  },
});
Slices.push(isIframe);

/*---------------------------------------------------------------*/

//build export objects
for (const Slice of Slices) {
  stateActions = { ...stateActions, ...Slice.actions };
  let reducer = { [Slice.name]: Slice.reducer };
  stateReducers = { ...stateReducers, ...reducer };
}

export { stateActions };
export { stateReducers };
