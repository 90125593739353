import React, { Component } from 'react';
import './splash.css';

import logo from "assets/logo/logo.svg";


class Splash extends Component {
    render() {
        return (
            <div className="splash">
                <img src={logo} alt='/'/>
            </div>
        )
    }
}

export default Splash;
