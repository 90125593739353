import React, { Component, Fragment } from "react";

import "./index.scss";

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      highlighted_item: -1,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.onExtraOptionClicked = this.onExtraOptionClicked.bind(this);
  }

  handleClick(event) {
    event.preventDefault();

    if (!(event.detail === 0)) {
      let newState = !this.state.open;
      this.setState({ open: newState });
    }
  }

  handleClickOutside() {
    this.setState({ open: false });
    this.props.onClickOutside();
  }

  handleOptionClick(event) {
    event.stopPropagation();
    event.preventDefault();

    //this.setState({open: false});
    let selected_item = this.props.options.find((item) => {
      return item.id === parseInt(event.target.id);
    });

    this.setState({ open: false });

    this.props.onSelect(selected_item);
  }

  handleKeyUp(event) {
    let highlighted_item = this.state.highlighted_item;
    switch (event.key) {
      case "ArrowDown":
        highlighted_item + 1 > this.props.options.length - 1
          ? (highlighted_item = 0)
          : highlighted_item++;
        this.setState({ highlighted_item: highlighted_item });
        break;

      case "ArrowUp":
        highlighted_item - 1 < 0 ? (highlighted_item = -1) : highlighted_item--;
        this.setState({ highlighted_item: highlighted_item });
        break;

      case "Enter":
        if (this.state.open === false) {
          this.setState({ open: true });
        } else {
          let selected_item = this.props.options[highlighted_item];
          if (highlighted_item !== -1) this.props.onSelect(selected_item);
          else this.props.onKeyDown();
          this.setState({ open: false });
        }
        break;

      case "Escape":
        this.setState({ highlighted_item: 0, open: false });
        break;
      default:
        break;
    }
  }

  onExtraOptionClicked(event){
    this.setState({ open: false });
    this.props.extraOptionClicked(event);
  }


  render() {
    const {
      options,
      label = "",
      placeholder = "",
      customPlaceholder = false,
      query = "",
      disabled = false,
      className = "",
      onChange,
      showError = false,
      errorMessage = "",
      extraOptionText,
    } = this.props;

    let activeClass = this.state.open && options.length > 0 ? "active" : "";

    return (
      <Fragment>
        {label !== "" && <label> {label}:</label>}
        <div
          className={
            "auto_wrapper " +
            className +
            " " +
            activeClass +
            (disabled ? " disabled" : "")
          }
          onBlur={this.handleClickOutside}
        >
          <input
            id={'auto-input-id'}
            className="auto_input"
            type="text"
            onClick={(e) => this.handleClick(e)}
            onChange={(e) => onChange(e)}
            value={query}
            onKeyUp={this.handleKeyUp}
          />

          {placeholder === "" && (
            <div className={"placeHolder " + (customPlaceholder && query === "" ? "enable" : "")}>
              {customPlaceholder}
            </div>
          )}

          {placeholder !== "" && (
            <span className={ "placeHolder " + (customPlaceholder && query === "" ? "enable" : "")}>
              {placeholder}
            </span>
          )}

          <ul className="auto_menu">
            {options.map((item, index) => {
              return (
                <li
                  id={item.id}
                  className={
                    "auto_option " +
                    (index === this.state.highlighted_item ? "highlight" : "")
                  }
                  key={index}
                  onMouseDown={this.handleOptionClick}
                >
                  {item.value}
                </li>
              );
            })}
            <li className="auto_option" onMouseDown={this.onExtraOptionClicked}>{extraOptionText}</li>
            {/* {moreListOptions} */}
          </ul>
          {showError ? <div className="error-text">{errorMessage}</div> : ""}
        </div>
      </Fragment>
    );
  }
}
export default AutoComplete;
