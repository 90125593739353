import React from "react";
import { Component } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";

import * as popups from "constants/popup-types";

//Import components
import MainMenu from "./MainMenu";
import Burger from "../../components/common/buttons/burger/burger";
import * as routes from "../../constants/routes";

//Import actions
import Actions from "redux/actions";

//Assets
import Logo from "assets/logo/logo.svg";
import like_icon from "assets/love-icons/two-hearts.svg";
import smart_agent from "assets/icons/smart-agent.svg";
import lovedRed from "assets/love-icons/loved_icon_red.svg";
import "./header.scss";
import { NavLink } from "react-router-dom";


class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: "",
      show_hover: false,
      showBtn: false,
      heart_red: false,
    };

    this.handleFavoritesClick = this.handleFavoritesClick.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);

    this.interval = false;
  }
  componentDidMount() {
    if (this.props.btnId) {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmountn() {
    if (this.props.btnId) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }
  handleScroll = (event) => {
    if (document.getElementById(this.props.btnId)) {
      const offset = document.getElementById(this.props.btnId).offsetTop;
      if (window.pageYOffset >= offset)
        this.setState({
          showBtn: true,
        });
      else
        this.setState({
          showBtn: false,
        });
    }
  };

  handleBurgerClick = () => {
    const new_state = !this.props.burgerMenuState;
    this.props.setBurgerState(new_state);
  };

  handleFavoritesClick() {
    this.props.addPopup({ type: popups.FAVORITES });
  }

  handleLogoClick() {
      window.scrollTo(0, 0); 
  }


  onMouseOver() {
    document.getElementById("love_icon").src = lovedRed;

    if (Object.keys(this.props.favorites).length < 1) {
      this.setState({ show_hover: true });
    }
  }

  onMouseOut() {
    document.getElementById("love_icon").src = like_icon;
    this.setState({ show_hover: false });
  }

  render() {
    const text = this.props.text;
    const deviceState = this.props.deviceState;

    if(Object.keys(this.props.favorites).length > 0) {
      this.state.heart_red = true;
    }else{
      this.state.heart_red = false;
    }

    return (
      <header>
        <div className="header-warrper">
          {deviceState.notDesktop && (
            <Burger onClick={this.handleBurgerClick} />
          )}
          {deviceState.isDesktop && (
            <div className="personal-wrapper">
              <NavLink to={routes.smartagent}>
                <div className="item">
                  <img
                    id="smart-agent"
                    className="icon"
                    src={smart_agent}
                    alt={text["smart_agent"]}
                  />
                  <span className="text">{text["smart_agent"]}</span>
                </div>
              </NavLink>

              <div className="divider-wrapper">
                <div className="divider" />
              </div>
              <div className="item ">
                <img
                  id="love_icon"
                  className="icon"
                  src={this.state.heart_red ? lovedRed : like_icon}
                  alt={text["header_loved"]}
                  onClick={this.handleFavoritesClick}
                />
                {
                  this.state.heart_red &&
                    <span className={'count'}>{Object.keys(this.props.favorites.records).length}</span>
                }
                <div className="text">{text["header_loved"]}</div>
              </div>
            </div>
          )}

          {deviceState.isDesktop && <MainMenu />}
          {deviceState.isDesktop && this.props.getButtonFunction && (
            <div className={`button ${this.state.showBtn ? "show" : ""}`}>
              {this.props.getButtonFunction()}
            </div>
          )}
          <NavLink to={routes.home} onClick={this.handleLogoClick}>
            <div className="logo_wrapper">
              <img
                className="logo"
                src={Logo}
                alt="logo"
              />
            </div>
          </NavLink>
        </div>
      </header>
    );
  }
}

//connect to redux store
const mapStateToProps = (store) => {
  return {
    deviceState: store.deviceState,
    burgerMenuState: store.burgerState,
    favorites: store.Favorites,
    text: store.gd.translationsArr,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBurgerState: (state) => dispatch(Actions.setBurger(state)),
    addPopup: (payload) => dispatch(Actions.addPopup(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(Header);
