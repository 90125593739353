const googleAnalytics = (function () {

    const Templates = {
        personalDetails: {
            event: 'reserve_a_car_stage1',
            id: '',
            car_model: '',
            car_year: '',
            car_hand: '',
            kilometers: '',
            price: '',
            'Agency Name': ''
        },
        enterOtp: {
            event: 'reserve_a_car_stage2',
            id: '',
            car_model: '',
            car_year: '',
            car_hand: '',
            kilometers: '',
            price: '',
            'Agency Name': ''
        },
        creditCardDetails: {
            event: 'reserve_a_car_stage3',
            id: '',
            car_model: '',
            car_year: '',
            car_hand: '',
            kilometers: '',
            price: '',
            'Agency Name': ''
        },
        selectingDate: {
            event: 'reserve_a_car_stage4',
            id: '',
            car_model: '',
            car_year: '',
            car_hand: '',
            kilometers: '',
            price: '',
            'Agency Name': ''
        },
        endOrderDetails: {
            event: 'reserve_a_car_stage5',
            id: '',
            car_model: '',
            car_year: '',
            car_hand: '',
            kilometers: '',
            price: '',
            'Agency Name': ''
        },
        productImpressions: {
            event: 'productImpressions',
            ecommerce: {
                currencyCode: 'ILS',
                impressions: [],
            },
        },
        product: {
            name: '',
            id: '',
            price: '',
            brand: 'Toyota',
            quantity: 1,
            coupon: ''
        },
        productClick: {
            event: 'productClick',
            ecommerce: {
                click: {
                    products: []
                }
            },
        },
        productDetails: {
            event: 'productDetails',
            ecommerce: {
                detail: {
                    products: []
                }
            },
        },
        addToCart: {
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'ILS',
                add: {
                    products: []
                }
            },
        },
        checkout: {
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: {step: ''},
                    products: []
                }
            },
        },
        purchase: {
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: '',
                        affiliation: 'Online Store',
                        revenue: '',
                        tax: '',
                        shipping: '',
                        coupon: '',

                    },
                    products: []
                }
            },
        },
        ContactUsSuccess: {
            event: 'ContactUsSuccess',
            'Agency Name': '',
        },
        ContactUsFail: {
            event: 'ContactUsFail',
            ContactUsFailName: '',
        },
        ContactUs: {
            event: 'ContactUs',
            'Contact Channel': '',
        },
        AddProductToWishlist: {
            event: 'AddProductToWishlist',
            'AddProductToWishlistProd': '',
        },
        SocialMediaClick: {
            event: 'SocialMediaClick',
            'SocialMediaClickName': '',
        },
        Search: {
            event: 'Search',
            'search term': '',
        },
        SearchByCategoey: {
            event: '',
            'Search option': '',
        },
        CarPageContactUs:{
            event:'contact_us_car_page_submission',
            'id':'',
            'car_model':'',
            'car_year':'',
            'car_hand':'',
            'kilometers':'',
            'price':'',
            'agency_name':'',
        },
        SmartAgentForm:{
            event:'smart_agent_submission',
        },
        TradeInForm:{
            event:'trade_in_form_submission',
        }
    }

    const generateTemplate = function (type) {
        let template = {};
        switch (type) {
            case 'personalDetails':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.personalDetails)));
                break;

            case 'enterOtp':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.enterOtp)));
                break;

            case 'creditCardDetails':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.creditCardDetails)));
                break;

            case 'selectingDate':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.selectingDate)));
                break;

            case 'endOrderDetails':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.endOrderDetails)));
                break;
            case 'productImpressions':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.productImpressions)));
                break;
            case 'product':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.product)));
                break;
            case 'productClick':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.productClick)));
                break;
            case 'productDetails':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.productDetails)));
                break;
            case 'addToCart':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.addToCart)));
                break;
            case 'checkout':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.checkout)));
                break;
            case 'purchase':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.purchase)));
                break;
            case 'ContactUsSuccess':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.ContactUsSuccess)));
                break;
            case 'ContactUsFail':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.ContactUsFail)));
                break;
            case 'ContactUs':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.ContactUs)));
                break;
            case 'AddProductToWishlist':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.AddProductToWishlist)));
                break;
            case 'SocialMediaClick':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.SocialMediaClick)));
                break;
            case 'Search':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.Search)));
                break;
            case 'SearchByCategoey':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.SearchByCategoey)));
                break;
            case 'CarPageContactUs':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.CarPageContactUs)));
                break;
            case 'SmartAgentForm':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.SmartAgentForm)));
                break;
            case 'TradeInForm':
                Object.assign(template, JSON.parse(JSON.stringify(Templates.TradeInForm)));
                break;

            default:
                break;
        }

        return template;
    }

    const personalDetailsView = function (data) {
        const payload = generateTemplate('personalDetails');
        payload.id = data.car_id;
        payload.car_model = data.car_model;
        payload.car_year = data.car_year;
        payload.car_hand = data.car_hand;
        payload.kilometers = data.kilometers;
        payload.price = data.price;
        payload['Agency Name'] = data.agency_name;
        push(payload);
    }

    const enterOtpView = function (data) {
        const payload = generateTemplate('enterOtp');
        payload.id = data.car_id;
        payload.car_model = data.car_model;
        payload.car_year = data.car_year;
        payload.car_hand = data.car_hand;
        payload.kilometers = data.kilometers;
        payload.price = data.price;
        payload['Agency Name'] = data.agency_name;
        push(payload);
    }

    const creditCardDetailsView = function (data) {
        const payload = generateTemplate('creditCardDetails');
        payload.id = data.car_id;
        payload.car_model = data.car_model;
        payload.car_year = data.car_year;
        payload.car_hand = data.car_hand;
        payload.kilometers = data.kilometers;
        payload.price = data.price;
        payload['Agency Name'] = data.agency_name;
        push(payload);
    }

    const selectingDateView = function (data) {
        const payload = generateTemplate('selectingDate');
        payload.id = data.car_id;
        payload.car_model = data.car_model;
        payload.car_year = data.car_year;
        payload.car_hand = data.car_hand;
        payload.kilometers = data.kilometers;
        payload.price = data.price;
        payload['Agency Name'] = data.agency_name;
        push(payload);
    }

    const endOrderDetailsView = function (data) {
        const payload = generateTemplate('endOrderDetails');
        payload.id = data.car_id;
        payload.car_model = data.car_model;
        payload.car_year = data.car_year;
        payload.car_hand = data.car_hand;
        payload.kilometers = data.kilometers;
        payload.price = data.price;
        payload['Agency Name'] = data.agency_name;
        push(payload);
    }

    const productImpressions = function (data) {
        const payload = generateTemplate('productImpressions');
        data.forEach(product => {
            const templateProduct = generateTemplate('product')
            templateProduct.name = product.title + ' ' + product.sub_title;
            templateProduct.id = product.id;
            templateProduct.price = product.price;
            payload.ecommerce.impressions.push(templateProduct)
        });
        push(payload);
    }

    const productClick = function (data) {
        const payload = generateTemplate('productClick');
        const templateProduct = generateTemplate('product')
        templateProduct.name = (data.model || data.title) + ' ' + data.sub_title;
        templateProduct.id = data.id;
        templateProduct.price = data.price;
        templateProduct.position = 1;
        payload.ecommerce.click.products.push(templateProduct)
        push(payload);
    }

    const productDetails = function (data) {
        const payload = generateTemplate('productDetails');
        const templateProduct = generateTemplate('product')
        templateProduct.name = (data.model || data.title) + ' ' + (data.sub_title || data.submodel);
        templateProduct.id = data.id;
        templateProduct.price = data.price;
        payload.ecommerce.detail.products.push(templateProduct)
        push(payload);
    }

    const addToCart = function (data) {
        const payload = generateTemplate('addToCart');
        const templateProduct = generateTemplate('product')
        templateProduct.name = (data.model || data.title) + ' ' + (data.sub_title || data.submodel);
        templateProduct.id = data.id;
        templateProduct.price = data.price;
        payload.ecommerce.add.products.push(templateProduct);
        push(payload);
    }

    const checkout = function (data) {
        const payload = generateTemplate('checkout');
        const templateProduct = generateTemplate('product')
        templateProduct.name = (data.model || data.title) + ' ' + (data.sub_title || data.submodel);
        templateProduct.id = data.id;
        templateProduct.price = data.price;
        payload.ecommerce.checkout.actionField.step = data.step;
        payload.ecommerce.checkout.products.push(templateProduct);
        push(payload);
    }

    const purchase = function (data) {
        const payload = generateTemplate('purchase');
        const templateProduct = generateTemplate('product')
        templateProduct.name = (data.model || data.title) + ' ' + (data.sub_title || data.submodel);
        templateProduct.id = data.id;
        templateProduct.price = data.price;
        payload.ecommerce.purchase.actionField.id = data.orderId;
        payload.ecommerce.purchase.actionField.revenue = data.price;
        payload.ecommerce.purchase.products.push(templateProduct);
        push(payload);
    }

    const ContactUsSuccess = function (data) {
        const payload = generateTemplate('ContactUsSuccess');
        payload['Agency Name'] = data.agencyName;
        push(payload);
    }

    const ContactUsFail = function (data) {
        const payload = generateTemplate('ContactUsFail');
        payload.ContactUsFailName = data.failName;
        push(payload);
    }

    const ContactUs = function (data) {
        const payload = generateTemplate('ContactUs');
        payload['Contact Channel'] = data.contactChanel;
        push(payload);
    }

    const AddProductToWishlist = function (data) {
        const payload = generateTemplate('AddProductToWishlist');
        payload['AddProductToWishlistProd'] = data.productName;
        push(payload);
    }

    const SocialMediaClick = function (data) {
        const payload = generateTemplate('SocialMediaClick');
        payload['SocialMediaClickName'] = data.shareChanel;
        push(payload);
    }

    const Search = function (data) {
        const payload = generateTemplate('Search');
        payload['search term'] = data.query;
        push(payload);
    }

    const SearchByCategoey = function (data) {
        const payload = generateTemplate('SearchByCategoey');
        payload['event'] = data.event;
        payload['Search option'] = data.searchOption;
        push(payload);
    }

    const CarPageContactUs = function(data){
        const payload=generateTemplate('CarPageContactUs')
        payload ['id']=data.id
        payload ['car_model']=data.car_model
        payload ['car_year']=data.car_year
        payload ['car_hand']=data.car_hand
        payload ['kilometers']=data.kilometers
        payload ['price']=data.price
        payload ['agency_name']=data.agency_name
        push(payload)
    }
    const SmartAgentForm = function(){
       const payload=generateTemplate('SmartAgentForm')
       push(payload)
    }
    const TradeInForm = function(){
        const payload=generateTemplate('TradeInForm')
        push(payload)
    }
    const push = function (payload) {
        window.dataLayer.push(payload)
    };

    return { personalDetailsView, enterOtpView, creditCardDetailsView, selectingDateView, endOrderDetailsView, productImpressions, productClick, productDetails,
        addToCart, checkout, purchase, ContactUsSuccess, ContactUsFail, ContactUs, AddProductToWishlist, SocialMediaClick ,Search, SearchByCategoey, CarPageContactUs,
        SmartAgentForm , TradeInForm}

})()

const googleAnalyticsManager = Object.freeze(googleAnalytics);
export default googleAnalyticsManager;
