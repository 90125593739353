import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import DynamicLink from "components/routing/dynamicLink/dynamicLink";

import { Autoplay } from "swiper";
import SwiperCore from "swiper";
import Actions from "redux/actions";
import * as popups from "constants/popup-types";

import "./Slider.scss";
import "swiper/swiper.scss";

import Play from "assets/icons/play.svg";
import { getFullMediaUrl } from "../../../../app/static_func";
import { Fragment } from "react";

SwiperCore.use([Autoplay]);

function Slider(props) {
  const dispatch = useDispatch();
  const deviceState = useSelector((store) => store.deviceState);
  let items = useSelector(store => store.gd.Home_page_main_slider);
  items = Object.values(items);
  const { timePerSlide, loop = true, slidesPerView = 1 } = props;

  // check how many slides are received
  // if more than one => the slider should autoplay, else => keep it static without autoplay
  const shouldAutoPlay = items.length > 1;
  const autoPlayObject = { autoplay: { delay: timePerSlide } };

  const swiperSettings = {
    spaceBetween: 10,
    slidesPerView: slidesPerView,
    loop: loop,
    ...(shouldAutoPlay ? autoPlayObject : { allowTouchMove: false }),
  };

  function onVideoClickHandler(ev, videourl) {
    ev.preventDefault();
    dispatch(
      Actions.addPopup({ type: popups.VIDEO, payload: { videourl: getFullMediaUrl(videourl) } })
    );
  }

  function getSlideContent(item) {

    const image = deviceState.isDesktop ? item.desktop_image.path : item.mobile_image.path;
    const alt = deviceState.isDesktop ? item.desktop_image.alt : item.mobile_image.alt;

    return (
      <>
        <img className="slide-image" src={getFullMediaUrl(image)} alt={alt} />
        {item.video &&
          <img
            className="video-play"
            src={Play}
            alt="\"
            onClick={(ev) => {
              onVideoClickHandler(ev, item.video);
            }}
          />}
      </>
    )
  }

  function getSlides() {
    const slides = items.map((item, index) => {

      return (
        <Fragment key={item.id}>
          {
            item.type.route ?
              <SwiperSlide key={item.id}>
                <DynamicLink className="slide-wrapper" data={item.type}>
                  {getSlideContent(item)}
                </DynamicLink>
              </SwiperSlide>
              :
              <SwiperSlide key={item.id}>
                <div className="slide-wrapper">
                  {getSlideContent(item)}
                </div>
              </SwiperSlide>
          }
        </Fragment>
      );
    });

    return slides;
  }

  return (
    <div className="slider-wrapper">
      <Swiper className="slider" {...swiperSettings}>
        {getSlides()}
      </Swiper>
    </div>
  );
}
export default Slider;
