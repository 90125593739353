import React, { Component, Fragment } from "react";
import {connect} from "react-redux";

import "./cookieMsg.scss";

import closeIcon from "assets/old-icons/close_icon.svg";

class CookieMsg extends Component {
  setCookie(cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie =
      cname +
      "=" +
      cvalue +
      ";" +
      expires +
      ";domain=" +
      window.location.hostname +
      ";path=/;";
    this.forceUpdate();
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  render() {
    let cookie = this.getCookie("toyota_cookie");
    let show_msg = false;

    if (cookie !== "true") {
      show_msg = true;
    }

    return (
      <div className={"cookieMsg " + (show_msg ? "" : "hide")}>
        {show_msg && (
          <Fragment>
            <div className="content_wrapper">
              <span className="msg">{this.props.text["cookies_msg"]}</span>
              <span className="cookieDivider" />
              <a href="/תנאי_שימוש"
                 className="privecyLink"
                 rel="noopener noreferrer"
                 >
                 {this.props.text["privacy_policy"]}
              </a>
            </div>
            <img
              className="close_img"
              src={closeIcon}
              alt="/"
              onClick={() => this.setCookie("toyota_cookie", true)}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

//add GD categories and deviceState to props
const mapStateToProps = (store) => {
  return {
      text: store.gd["translationsArr"]
  };
};
//map a doLgin function to props
const mapDispatchToProps = (dispatch) => {
  return {
  };
};

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(CookieMsg);