import Store from "redux/index";
export function getFullMediaUrl(path) {
  const url = Store.getState().url;
  return url.media_url + path;
}

export function setLocalStorageItem(key, item) {
  try {
    let myStorage = window.localStorage;
    myStorage.setItem(key, item);
  } catch (e) {
    console.error("local storage error:", e);
  }
}

export function getLocalStorageItem(key) {
  try {
    const myStorage = window.localStorage;
    // const item = JSON.parse(myStorage.getItem(key));
    const item = myStorage.getItem(key);
    return item;
  } catch (e) {
    console.error("local storage error:", e);
  }
}

export function clearLocalStorage() {
  window.localStorage.clear();
}

export function setSessionStorageItem(key, item) {
  try {
    const sessionStorage = window.sessionStorage;
    sessionStorage.setItem(key, item);
  } catch (e) {
    console.error("local storage error:", e);
  }
}

export function getSessionStorageItem(key) {
  try {
    const sessionStorage = window.sessionStorage;
    // const item = JSON.parse(myStorage.getItem(key));
    const item = sessionStorage.getItem(key);
    return item;
  } catch (e) {
    console.error("local storage error:", e);
  }
}

export function clearSessionStorage() {
  window.sessionStorage.clear();
}

export function createFiltersUrl(obj) {
  let path = "?";
  const arrParsed = JSON.parse(JSON.stringify(obj));

  if (
    arrParsed.hasOwnProperty("latitude") ||
    arrParsed.hasOwnProperty("longitude")
  ) {
    delete arrParsed.latitude;
    delete arrParsed.longitude;
  }

  Object.keys(arrParsed).forEach((index) => {
    path = path.concat(index);
    path = path.concat("=");
    path = path.concat(obj[index]);
    path = path.concat("&");
  });
  path = path.slice(0, -1);
  return path;
}

export function parseUrlParams() {
  let payload = {};
  let queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.entries();

  for (const entry of entries) {
    payload[entry[0]] = entry[1];
  }
  return payload;
}

export function formatNumber(num) {
  if (num !== undefined) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
}

export function setCookie(key, value, exp) {
  document.cookie = key + "=" + value + ";" + "expires=" + exp + ";";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function sortByOrderNum(arr) {
  return arr.sort((a, b) => {
    let comparison = 0;
    if (a.order_num > b.order_num) {
      comparison = 1;
    } else if (a.order_num < b.order_num) {
      comparison = -1;
    }
    return comparison;
  });
}

export function peepsScoreCalc(score) {
  score = parseFloat(score);
  let res = 0.4 * (10 - score) + score;
  return res * 10;
}

//generate unique IDs
export function generateUniqueId(length) {
  var result = [];
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}

export function decodeArrayToObject(array, fiedlName) {
  const object = {};
  for (const key in array) {
    const item = array[key];
    object[`${fiedlName}[${key}]`] = item;
  }
  return object;
}

export function getServicesByBitwise(bitwise, services) {
  var servicesArray = [];
  for (const service in services) {
    if (bitwise & services[service]["bitwise_value"])
      servicesArray.push(services[service]);
  }
  return servicesArray;
}

export function deleteIdFromList(list, id) {
  const index = list.indexOf(id);
  if (index > -1) {
    list.splice(index, 1);
  }
  return list;
}

export function isCombinedLoanFunding(carYear) {
  const carAge = new Date().getFullYear() - carYear;
  return carAge <= 5;
}

export function isFunding(carPrice) {
  const funding = Store.getState().gd.funding;
  const fundingRecommended = funding.combined_loan.packages[1].recommended[1];
  const fundingMaxPrice = fundingRecommended["max_car_price"];

  return parseInt(fundingMaxPrice) > parseInt(carPrice);
}
