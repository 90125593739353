import React from "react";
import { Component } from "react";
import { Fragment } from "react";
import { lazy } from "react";
import { Suspense } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";

import Actions from "redux/actions";
import store from "redux/index";

//Import components
import Header from "./containers/header/header";
// import Footer from "./containers/footer/footer";
// import Loader from "./components/common/loader/loader";
// import LottieAnimation from "components/animation/lottie";
import HomeLoader from "components/animation/HomeLoader";
import Home from "./containers/home/home";
// import Popups from "./containers/popups/index";
// import Popups from "popups/index";
// import BurgerMenu from "./containers/burger/burger";
import Splash from "./containers/splash/splash";
import CookieMsg from "./containers/cookiesMsg/index";
// import MetaTags from "containers/metaTags";
import MetaTags from "containers/metaTagsNew";
import ScrollToTop from "components/routing/scroll_top";
import NotFoundRedirect from "./components/NotFoundRedirect";

//css
import "./scss/app.scss";
import "./scss/animations.scss";

//routing constants
import * as routes from "./constants/routes";

//actions
import Api from "api/requests";
import {
  decodeArrayToObject,
  getLocalStorageItem,
  getSessionStorageItem,
  setSessionStorageItem,
} from "./app/static_func";
import * as keys from "./constants/storage-keys";

import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
// import Results from "containers/results";
// import About from "containers/content/about";
// import Car from "containers/car";
// import Order from "containers/order/order";
// import HowItWorks from "containers/content/howItWorks";
// import Contact from "containers/content/contact/contact";
// import Content from "containers/content/contentPage/Content";
// import QA from "containers/content/qestions_answers/qa";
// import ScreenSaver from "containers/screenSaver";
// import Agencies from "containers/agencies/agencies";
// import Agency from "containers/agency/agency";
// import AgencyIframe from "containers/AgencyIframe/AgencyIframe";
// import SmartAgent from "containers/smartagent/SmartAgent";
// import SmartAgentResults from "containers/smartagent/results/results";
// import Page404 from "containers/page404/404";
// import Terms from "./containers/terms";
// import Magazine from "./containers/magazine";

//begin code splitting

const Order = lazy(() => import("containers/order/order"));
const Results = lazy(() => import("containers/results/index"));
const Car = lazy(() => import("containers/car/index"));
const About = lazy(() => import("containers/content/about/index"));
const HowItWorks = lazy(() => import("containers/content/howItWorks/index"));
const Contact = lazy(() => import("containers/content/contact/contact"));
const Content = lazy(() => import("containers/content/contentPage/Content"));
const QA = lazy(() => import("containers/content/qestions_answers/qa"));
const Agencies = lazy(() => import("containers/agencies/agencies"));
const Agency = lazy(() => import("containers/agency/agency"));
const AgencyIframe = lazy(() => import("containers/AgencyIframe/AgencyIframe"));
const ModelIframe = lazy(() => import("containers/Modelframe/ModelIframe"));
const SmartAgent = lazy(() => import("containers/smartagent/SmartAgent"));
const SmartAgentResults = lazy(() =>
  import("containers/smartagent/results/results")
);
const Terms = lazy(() => import("containers/terms"));
const Magazine = lazy(() => import("containers/magazine"));
const Popups = lazy(() => import("popups/index"));
const BurgerMenu = lazy(() => import("containers/burger/burger"));
const Footer = lazy(() => import("containers/footer/footer"));
const Loader = lazy(() => import("components/common/loader/loader"));
const pagesMap = lazy(() => import("containers/pagesMap"));
const Model = lazy(() => import("containers/model"));
const TradeIn = lazy(() => import("containers/trade-in"));

const options = {
  root: document.body,
  rootMargin: "50px",
  threshold: 0.0,
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRequestsDone: false,
      isVisible: false,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    Api.getHostUrl({ config: { method: "get" } }).then((response) => {
      if (response?.status) {
        Api.generalDeclaration().then(() => {
          this.setState({ initialRequestsDone: true });
          let splash = document.getElementById("splash");
          splash.remove();
        });

        const localStorageFavorites = decodeArrayToObject(
          JSON.parse(getLocalStorageItem(keys.FAVORITES)),
          "id"
        );
        if (Object.keys(localStorageFavorites).length > 0) {
          Api.getSpecificCarById({ payload: localStorageFavorites });
        }
      }
    });

    this.getLocation();

    const params = new URLSearchParams(this.props.location.search);
    const CampaignId = params.get("campid");
    const AdvertiserGroupId = params.get("adgroupid");
    const AdvertisingId = params.get("ad_id");
    const GCLID = params.get("gclid");
    const utm_source = params.get("utm_source");
    const utm_medium = params.get("utm_medium");
    const utm_campaign = params.get("utm_campaign");

    if (utm_source || utm_medium || utm_campaign || CampaignId || AdvertiserGroupId || AdvertisingId || GCLID) {
      const payload = {};

      CampaignId && (payload["CampaignId"] = CampaignId);
      AdvertiserGroupId && (payload["AdvertiserGroupId"] = AdvertiserGroupId);
      AdvertisingId && (payload["AdvertisingId"] = AdvertisingId);
      GCLID && (payload["GCLID"] = GCLID);
      utm_source && (payload["SubDirected"] = utm_source);
      utm_medium && (payload["SubSubDirected"] = utm_medium);
      utm_campaign && (payload["Campaign"] = utm_campaign);

      setSessionStorageItem(keys.UTM_PARAMS, JSON.stringify(payload));
    }
    const utmParams = JSON.parse(getSessionStorageItem(keys.UTM_PARAMS));
    if (utmParams) store.dispatch(Actions.setUtmParams(utmParams));
  }

  componentDidUpdate(prevProps, prevState) {
    const observer = new IntersectionObserver(
      this.callbackFunction.bind(this),
      options
    );
    if (this.ref.current) observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    if (ref.current) observer.unobserve(ref.current);
  }

  callbackFunction(entries) {
    const [entry] = entries;
    if (this.state.isVisible !== entry.isIntersecting) {
      this.setState({ isVisible: entry.isIntersecting });
    }
  }

  showHeader() {
    const contentsWithCarTitle = this.props.contentRoutes.filter((content) =>
      content.title.includes(routes.car.substring(1))
    );
    const contentsCarTitles = contentsWithCarTitle.map(
      (content) => content.title
    );

    if (this.props.location.pathname.includes(routes.agencyCarResults)) {
      return false;
    }

    if (this.props.location.pathname.includes(routes.modelCarResults)) {
      return false;
    }

    if (
      !contentsCarTitles.includes(this.props.location.pathname.substring(1)) &&
      this.props.location.pathname.includes(routes.car)
    ) {
      return false;
    }
    return true;
  }
  showFooterAndCookieMsg() {
    if (this.props.location.pathname.includes(routes.agencyCarResults)) {
      return false;
    }
    if (this.props.location.pathname.includes(routes.modelCarResults)) {
      return false;
    }
    return true;
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        this.props.setCurrentLocation({ latitude, longitude });
      });
    }
  };

  render() {
    const isMobile = this.props.deviceState !== "desktop";
    const showPopup = this.props.popupsArray.length > 0;
    const showBurgerMenu = isMobile && this.props.burgerMenuState;
    const animateBurger = isMobile && this.props.burgerMenuAnimate;
    const showLoader = this.props.showLoader;

    if (showBurgerMenu) {
      document.body.classList.add("burger");
    } else {
      document.body.classList.remove("burger");
    }

    const res = this.props.location.pathname.split("/");

    if (this.state.initialRequestsDone) {
      return (
        <ErrorBoundary>
          <Fragment>
            <div
              className={
                "app" +
                (res[1] === "car" ? " car" : "") +
                (animateBurger ? " burger" : "")
              }
            >
              {this.showHeader() && <Header history={this.props.history} />}
              <main className="main">
                <Switch>
                  <Redirect
                    from="/:url*(/+)"
                    to={this.props.location.pathname.slice(0, -1)}
                  />
                  {/*<Route exact path={routes.root}>*/}
                  {/*  <Redirect to={routes.home} />*/}
                  {/*</Route>*/}
                  <Route exact path={routes.home} component={Home} />
                  {/* <Route exact path={routes.results + "/:query?"} component={Results} /> */}
                  <Route
                    exact
                    path={routes.results + "/:query?"}
                    render={LazyLoadComponent(Results)}
                  />
                  {/* <Route path={routes.car + "/:car_id/:title"} component={Car} /> */}
                  <Route
                    path={routes.car + "/:car_id/:title"}
                    render={LazyLoadComponent(Car)}
                  />
                  {/*<Route path={routes.Order + "/:car_id"} component={Order} />*/}
                  <Route
                    path={routes.order + "/:car_id"}
                    render={LazyLoadComponent(Order)}
                  />
                  {/* <Route exact path={routes.about} component={About} /> */}
                  <Route
                    exact
                    path={routes.about}
                    render={LazyLoadComponent(About)}
                  />
                  {/* <Route exact path={routes.howItWorks} component={HowItWorks} /> */}
                  <Route
                    exact
                    path={routes.howItWorks}
                    render={LazyLoadComponent(HowItWorks)}
                  />
                  {/* <Route exact path={routes.contact} component={Contact} /> */}
                  <Route
                    exact
                    path={routes.contact}
                    render={LazyLoadComponent(Contact)}
                  />
                  {/* <Route exact path={routes.qa} component={QA} /> */}
                  <Route
                    exact
                    path={routes.qa}
                    render={LazyLoadComponent(QA)}
                  />
                  {/* <Route exact path={routes.agencies} component={Agencies} /> */}
                  <Route
                    exact
                    path={routes.agencies}
                    render={LazyLoadComponent(Agencies)}
                  />
                  {/* <Route exact path={routes.agency + "/:id"} component={Agency} /> */}
                  <Route
                    exact
                    path={routes.agency + "/:id"}
                    render={LazyLoadComponent(Agency)}
                  />
                  {/* <Route exact path={routes.agencyCarResults + "/:id"} component={AgencyIframe} /> */}
                  <Route
                    exact
                    path={routes.agencyCarResults + "/:id"}
                    render={LazyLoadComponent(AgencyIframe)}
                  />
                  <Route
                    exact
                    path={routes.modelCarResults + "/:id"}
                    render={LazyLoadComponent(ModelIframe)}
                  />
                  {/* <Route exact path={routes.smartagent} component={SmartAgent} /> */}
                  <Route
                    exact
                    path={routes.smartagent}
                    render={LazyLoadComponent(SmartAgent)}
                  />
                  {/* <Route exact path={routes.smartAgentResults} component={SmartAgentResults} /> */}
                  <Route
                    exact
                    path={routes.smartAgentResults}
                    render={LazyLoadComponent(SmartAgentResults)}
                  />
                  {/* <Route exact path={routes.terms} component={Terms} /> */}
                  <Route
                    exact
                    path={routes.terms}
                    render={LazyLoadComponent(Terms)}
                  />
                  {/* <Route exact path={routes.magazine} component={Magazine} /> */}
                  <Route
                    exact
                    path={routes.magazine}
                    render={LazyLoadComponent(Magazine)}
                  />
                  <Route
                    exact
                    path={routes.papgesMap}
                    render={LazyLoadComponent(pagesMap)}
                  />
                  <Route
                    exact
                    path={routes.model + "/:modelId"}
                    render={LazyLoadComponent(Model)}
                  />
                  {/* <Route exact path={routes.model + "/:modelId" + "/:modelName?"} render={LazyLoadComponent(Model)} /> */}
                  <Route
                    exact
                    path={routes.tradeIn}
                    render={LazyLoadComponent(TradeIn)}
                  />
                  {this.props.contentRoutes.map((page, index) => (
                    <Route
                      key={index}
                      exact
                      path={"/" + page.title}
                      render={LazyLoadComponent(Content)}
                    />
                  ))}
                  {/* <Route exact path={'/*'} component={Page404} /> */}
                  {/*<Route exact path={'/404'} render={LazyLoadComponent(Page404)} />*/}
                  <Route component={NotFoundRedirect} />
                </Switch>
              </main>
              {
                <footer ref={this.ref}>
                  <Suspense fallback={<div />}>
                    {this.showFooterAndCookieMsg() && this.state.isVisible && (
                      <Footer />
                    )}
                  </Suspense>
                </footer>
              }
              <MetaTags />
              {this.showFooterAndCookieMsg() && <CookieMsg />}
              {showPopup && (
                <Suspense
                  fallback={<HomeLoader className="full-screen-loader" />}
                >
                  <Popups history={this.props.history} />
                </Suspense>
              )}
              {showLoader && (
                <Suspense
                  fallback={<HomeLoader className="full-screen-loader" />}
                >
                  <Loader />
                </Suspense>
              )}
            </div>
            {showBurgerMenu && (
              <Suspense
                fallback={<HomeLoader className="full-screen-loader" />}
              >
                <BurgerMenu />
              </Suspense>
            )}
            <ScrollToTop />
          </Fragment>
        </ErrorBoundary>
      );
    } else {
      return <HomeLoader className="full-screen-loader" />;
    }
  }
}

function LazyLoadComponent(Component) {
  return (props) => (
    <Suspense fallback={<HomeLoader className="full-screen-loader" />}>
      <Component {...props} />
    </Suspense>
  );
}

//get required state from redux store and map them to the components props
const mapStateToProps = (store) => {
  return {
    gd: store.gd,
    deviceState: store.deviceState,
    showLoader: store.loaderState,
    popupsArray: store.popupsArray,
    burgerMenuState: store.burgerMenuState,
    burgerMenuAnimate: store.burgerMenuAnimate,
    contentRoutes: store.RouteTable,
    //location: store.currentLocationState,
  };
};
//map a actions to props
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentLocation: (payload) =>
      dispatch(Actions.setCurrentLocation(payload)),
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })
)(App);
