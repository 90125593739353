import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { formatNumber, isFunding } from "app/static_func";

import "./index.scss";
import NotFound from "assets/icons/not-found-cardc.svg";

function CardC(props) {
  const {
    id,
    model,
    km,
    pricePerMonth,
    price,
    agency,
    isOrdered,
    year,
    isBought,
    lockedIcon,
    boughtIcon,
    garage,
    getImages,
    routeTo,
    isLocked,
    isAvailable,
    enableFunding,
    isFromIframe = false
  } = props;

  const text = useSelector((store) => store.gd.translationsArr);

  return  !isLocked ? (
    <NavLink to={routeTo} className="buy-btn" onClick={props.btnClick} target={isFromIframe? '_blank' : '_self'}>
    <div className={`cardWrapper CardC ${isLocked}`} id={"vCard " + id}>
      {(isOrdered || isBought || !isAvailable) && (
        <div className="cover">
          <div className="message">
            {(isOrdered || !isAvailable) && (
              <div className="locked_icon">
                <img src={lockedIcon} alt="/" />
              </div>
            )}
            {isBought && <img className="keys_icon" src={boughtIcon} alt="/" />}
            <span className="locked_span">
              {isOrdered ? text["ordered_car"] : !isAvailable ? text["unavailable_car"] : text["sold_car"]}
            </span>
          </div>
        </div>
      )}
      <div className="info-wrapper">
        {getImages(NotFound)}

        <div className="name-km-agent">
          <h3 className="model">{model} {year}</h3>
          <span className="km">
            {formatNumber(km)} {text["km_initials"]}
          </span>
          <span className="agent">
            <img className="icon-garage" src={garage} alt="garage" />
            {agency}
          </span>
        </div>
      </div>
      <div className="divider-wrapper">
        <div className="divider"></div>
      </div>

      <div className="price-container">
        {enableFunding && isFunding(price) && <>
          <div className="price-wrapper ">
            <span className="starting-at">{text["starting_at"]}</span>
            <span className="price">
              {formatNumber(pricePerMonth)}
              <span className="month">{text["for_month"]}</span>
            </span>
          </div>
          <div className="divider-price">
            <div className="divider"></div>
          </div>
        </>}
        <div className="price-wrapper">
          <span className="full-price">{text["sell_price"]}</span>

          <span className="price">{formatNumber(price)}</span>
        </div>
          <button className="buy-btn">
            {text["vehicle_card_btn"]}
          </button>
      </div>
    </div></NavLink>) : (<div><div className={`cardWrapper CardC ${isLocked}`} id={"vCard " + id}>
    {(isOrdered || isBought || !isAvailable) && (
        <div className="cover">
          <div className="message">
            {(isOrdered || !isAvailable) && (
                <div className="locked_icon">
                  <img src={lockedIcon} alt="/" />
                </div>
            )}
            {isBought && <img className="keys_icon" src={boughtIcon} alt="/" />}
            <span className="locked_span">
              {isOrdered ? text["ordered_car"] : !isAvailable ? text["unavailable_car"] : text["sold_car"]}
            </span>
          </div>
        </div>
    )}
    <div className="info-wrapper">
      {getImages(NotFound)}

      <div className="name-km-agent">
        <h3 className="model">{model} {year}</h3>
        <span className="km">
            {formatNumber(km)} {text["km_initials"]}
          </span>
        <span className="agent">
            <img className="icon-garage" src={garage} alt="garage" />
          {agency}
          </span>
      </div>
    </div>
    <div className="divider-wrapper">
      <div className="divider"></div>
    </div>

    <div className="price-container">
      {enableFunding && isFunding(price) && <>
        <div className="price-wrapper ">
          <span className="starting-at">{text["starting_at"]}</span>
          <span className="price">
              {formatNumber(pricePerMonth)}
            <span className="month">{text["for_month"]}</span>
            </span>
        </div>
        <div className="divider-price">
          <div className="divider"></div>
        </div>
      </>}
      <div className="price-wrapper">
        <span className="full-price">{text["sell_price"]}</span>

        <span className="price">{formatNumber(price)}</span>
      </div>
      <button className="buy-btn">
        {text["vehicle_card_btn"]}
      </button>
    </div>
  </div></div>)
}

export default CardC;
