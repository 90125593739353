import { createSlice } from "@reduxjs/toolkit";

import { setGd } from "../data";

let Slices = [];
let formReducers = {};
let formActions = {};


/*---------------------------------------------------------------*/
export const orderForm = createSlice({
  name: "orderForm",
  initialState: {},
  reducers: {
    updateOrderForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetOrderForm: (state, action) => {
      return {};
    },
  },
});
Slices.push(orderForm);
/*---------------------------------------------------------------*/
export const smartAgentForm = createSlice({
  name: "smartAgentForm",
  initialState: {},
  reducers: {
    updateSmartAgentForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(setGd, (state, action) => {
      state.categories = [];
      state.models = [];
      state.agitation = [];
      state.areas = [];
      state.budget = action.payload.smart_agent.price;
      state.km =
        parseInt((action.payload.smart_agent.km.min +
          action.payload.smart_agent.km.max) /
        2);
      state.year = action.payload.smart_agent.year;
      state.fullName = "";
      state.phone = "";
      state.email = "";
      state.mailing = false;
    });
  },
});
Slices.push(smartAgentForm);
/*---------------------------------------------------------------*/

export const contactForm = createSlice({
  name: "contactForm",
  initialState: {},
  reducers: {
    updateContactForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetContactForm: (state, action) => {
      return {};
    },
  },
});
Slices.push(contactForm);
/*---------------------------------------------------------------*/

export const tradeInForm = createSlice({
  name: "tradeInForm",
  initialState: {},
  reducers: {
    updateTradeInForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetTradeInForm: (state, action) => {
      return {};
    },
  },
});
Slices.push(tradeInForm);

//build export objects
for (const Slice of Slices) {
  formActions = { ...formActions, ...Slice.actions };
  let reducer = { [Slice.name]: Slice.reducer };
  formReducers = { ...formReducers, ...reducer };
}

export { formActions };
export { formReducers };
