export const root = "/";
export const home = "/";
export const papgesMap = "/מפת_אתר"
export const contact = "/צור_קשר";
export const about = "/אודותינו";
export const results = "/תוצאות";
export const car = "/רכב";
export const order = "/הזמנה";
export const howItWorks = "/איך_זה_עובד";
export const qa = "/שאלות_ותשובות";
export const error = "/404";
export const smartagent = "/סוכן_חכם";
export const agencies = "/סוכנויות";
export const agency = "/סוכנות";
export const agencyCarResults = "/agency";
export const terms = "/תנאי_שימוש";
export const smartAgentResults = "/סוכן_חכם_תוצאות";
export const magazine = "/מגזין"
export const model = "/model"
export const tradeIn = "/trade-in"
export const modelCarResults = "/cars-iframe";