//React imports
import React from "react";
import ReactDOM from "react-dom";

//Redux imports
import { Provider } from "react-redux";
import store from "redux/index";

//Router
import { BrowserRouter, Route } from "react-router-dom";

//Device state is responsible for determining responsive view states(mobile,table,desktop)
import deviceState from "./app/device_state/index";

//Root component
import App from "./App";

//Device state handles responsive updates
new deviceState(store.dispatch);

window.store = store;

ReactDOM.render(
  <BrowserRouter basename={"/"}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
