import ApiManager from "api";
import Actions from "redux/actions";
import store from "redux/index";
import * as popupTypes from "constants/popup-types";

class Api extends ApiManager {
    constructor() {
        if (!Api.instance) {
            super();
            Api.instance = this;
        }

        return (Api.instance = this);
    }

    getHostUrl = (props = {}) => {
        const onSuccess = (response) => {
            let api = {
                base_url: response.data.url,
                get_methods: response.data.get_methods_arr,
                get_url: response.data.get_url,
            };
            this._updateApiParams(api);
            store.dispatch(Actions.setUrl(response.data));
        };
        return this._execute(props, "getHostUrl", onSuccess);
    };

    generalDeclaration = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setGd(response.data));
            store.dispatch(Actions.setAreas(response.data.areas));
            store.dispatch(Actions.setRouteTable(response.data.contentUrls));
        };

        return this._execute(props, "generalDeclaration", onSuccess);
    };

    checkValidationSMS = (props = {}) => {
        const onSuccess = () => {
            store.dispatch(Actions.setValidationSMS(true));
        };
        return this._execute(props, "checkValidationSMS", onSuccess);
    };

    checkOrder = (props = {}) => {
        const onSuccess = () => {
        };

        return this._execute(props, "checkOrder", onSuccess);
    }

    createOrder = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setCG(response.data));
        };
        const onFailure = (response) => {
            // console.log(response);
            // store.dispatch(Actions.setCG(response.data));
            const text = response?.err?.content !== undefined ? response.err.content : 'אירעה שגיאה בתהליך התשלום';
            store.dispatch(Actions.addPopup(
                {
                    type: popupTypes.ENSURE_ERROR,
                    payload: {
                        text: text,
                    }
                }
            ));
        };
        return this._execute(props, "createOrder", onSuccess, onFailure);
    };

    getAppointments = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAppointments(response.data));
        };

        return this._execute(props, "getAppointments", onSuccess);
    };

    getCar = (props = {}) => {

        const onSuccess = (response) => {
            store.dispatch(Actions.setCarResults(response.data));
        };
        const onFailure = (response) => {
            const text = response?.err?.content !== undefined ? response.err.content : 'תקלת שרת, אנא נסה שנית מאוחר יותר';
            store.dispatch(Actions.addPopup(
                {
                    type: popupTypes.ENSURE_ERROR,
                    payload: {
                        text: text,
                    }
                }
            ));

        };
        return this._execute(props, "getCar", onSuccess, onFailure);
    };

    getCars = (props = {}) => {
        const onSuccess = (response) => {
            if (store.getState().Refresh) {
                store.dispatch(Actions.setCarsResults(response.data.records));
            } else {
                store.dispatch(Actions.appendCarsResults(response.data.records));
            }

            if (response.data.metadata.search.length !== 0) {
                store.dispatch(Actions.setSearchResults(response.data.metadata.search));

            }
        };
        const onFailure = (response) => {
            store.dispatch(Actions.setBaseFilters(response.data.metadata.search.base_filters))
            store.dispatch(Actions.setSearchResults(response.data.metadata.search));
            store.dispatch(Actions.setCarsResults([]));
            store.dispatch(Actions.addPopup(
                {
                    type: popupTypes.API_ERROR,
                    payload: {
                        text: response.err.content,
                    }
                }
            ));
        }
        return this._execute(props, "getCars", onSuccess, onFailure);
    };

    getSpecificCarById = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setFavorites(response.data));
        };
        let successMethod = props.onSuccess ? props.onSuccess : onSuccess;
        return this._execute(props, "getSpecificCarById", successMethod);
    };

    getAgency = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAgencyDetails(response.data));
            typeof props.onSuccess === "function" && props.onSuccess(response.data);
        };
        return this._execute(props, "getAgency", onSuccess);
    };

    getCarsByQuery = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAutoCompleteResults(response.data.records));
        };
        const onFailure = () => {
        }
        return this._execute(props, "getCarsByQuery", onSuccess);
    };

    getContentPage = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setContentPage(response.data));
        };
        return this._execute(props, "getContentPage", onSuccess);
    };

    getFavorites = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setFavorites(response.data));
        };
        return this._execute(props, "getCar", onSuccess);
    };

    getMetaTags = (props = {}) => {
        const onSuccess = (response) => {
            let key = props.payload.objectId !== undefined
                ? `${props.payload.route}-${props.payload.objectId}`
                : props.payload.route !== undefined ? props.payload.route
                    : `${props.payload.route}-${props.payload.car_id}`;
            let payload = {
                route: [key],
                val: response.data.tags,
            };
            store.dispatch(Actions.setMetaTags(payload));
        };
        return this._execute(props, "getMetaTags", onSuccess);
    };

    sendNewLead = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setLeadStatus(response.status));
        };
        return this._execute(props, "newLead", onSuccess);
    };


    getManufacturers = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setManufacturersResults(response.data.records));
        };
        return this._execute(props, "getManufacturers", onSuccess);
    };

    sendBusinessLead = (props = {}) => {
        const onSuccess = (response) => {
        };
        return this._execute(props, "sendBusinessLead", onSuccess);
    };

    sendOrderSMS = (props = {}) => {
        return this._execute(props, "sendOrderSMS", props.onSuccess, props.onFailure);
    };

    checkOrderSMS = (props = {}) => {
        this._execute(props, 'checkOrderSMS', props.onSuccess, props.onFailure);
    }

    getOrderDetail = (props = {}) => {
        const onSuccess = () => {
            store.dispatch(Actions.setSummary(response.data));
        };
        return this._execute(props, "getOrderDetail", onSuccess);
    };

    getAllAgencies = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAllAgencies(response.data));
        };
        return this._execute(props, "getAllAgencies", onSuccess);
    };

    getAllModels = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setAllModels(response.data));
        };
        return this._execute(props, "getAllModels", onSuccess);
    };

    newLead = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(
                Actions.addPopup({
                    type: popupTypes.Generic,
                    payload: { text: props.popupText },
                })
            );
            store.dispatch(Actions.resetContactForm());
        };
        return this._execute(props, "newLead", onSuccess, props.onFailure || '');
    };

    subscribeToSmartAgent = (props = {}) => {
        const onSuccess = (response) => {
            if (response.data.length === 0) {
                store.dispatch(Actions.addPopup({ type: popupTypes.SmartAgent }));
            } else {
                store.dispatch(Actions.setSmartAgencyCarResults(response.data));
                props.onSuccessCb();
            }
        };
        return this._execute(props, "subscribeToSmartAgent", onSuccess);
    };

    getModelsByCategory = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setModels(response.data));
        };
        return this._execute(props, "getModelsByCategory", onSuccess);
    };

    startEnsureCar = (props = {}) => {
        return this._execute(props, "startEnsureCar", props.onSuccess, props.onFailure);
    };

    getMagazines = (props = {}) => {
        return this._execute(props, "getMagazines", props.onSuccess);
    };

    meetingTimeNotFound = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(Actions.setFinishOrder(response.data))
        };
        return this._execute(props, "meetingTimeNotFound", onSuccess);
    };

    validateReservationProcess = (props = {}) => {
        this._execute(props, 'validateReservationProcess', props.onSuccess, props.onFailure);
    }

    getModel = (props = {}) => {

        const onSuccess = (res) => {
            store.dispatch(Actions.setModelDetails(res.data));
            typeof props.onSuccess === "function" && props.onSuccess(res.data);
        }
        const onFailure = (response) => {
            const text = response?.err?.content !== undefined ? response.err.content : 'תקלת שרת, אנא נסה שנית מאוחר יותר';
            store.dispatch(Actions.addPopup(
                {
                    type: popupTypes.ENSURE_ERROR,
                    payload: {
                        text: text,
                    }
                }
            ));

        };

        this._execute(props, 'getModel', onSuccess, onFailure)
    }

    tradeIn = (props = {}) => {
        const onSuccess = (response) => {
            store.dispatch(
                Actions.addPopup({
                    type: popupTypes.Generic,
                    payload: { text: props.popupText },
                })
            );
            store.dispatch(Actions.resetTradeInForm());
        };
        return this._execute(props, "tradeIn", onSuccess);
    };

    getIframeInfo = (props = {}) => {
        const onSuccess = (response) => {
            if (store.getState().Refresh) {
                store.dispatch(Actions.setIframeModelCars(response.data.records));
                store.dispatch(Actions.setIframeModelCarsInfo({
                    title: response.data?.iframe_title,
                    totalResults: response.data?.total_results,
                    link: response.data?.iframe_title_link
                }));
            } else {
                store.dispatch(Actions.appendIframeModelCars(response.data.records));
            }
            typeof props.onSuccess === "function" && props.onSuccess(response.data);
        };
        return this._execute(props, "getIframeInfo", onSuccess);
    };
}

const instance = new Api();
Object.freeze(instance);
export default instance;
