import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animation from "./animation.json";

class LottieAnimation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: false
        };

        this.handleHover = this.handleHover.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleHover() {
        this.setState({isStopped: false})
    }
    handleLeave() {
        this.setState({isStopped: true})
    }

    render(){
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const {className = ''} = this.props;


        return(
            <div className = {'lottie_wrapper ' + className }>
                <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
            </div>
        )
    }
}

export default LottieAnimation